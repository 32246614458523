


































































import { Component, Vue } from 'vue-property-decorator';
import ExportUiStateModule from '@/store/modules/ExportUiStateModule';
import ExplorerDataModule from '@/store/modules/ExplorerDataModule';
import { IExportCriteria } from '../../store/types/IExportCriteria';
import { ICancelExport } from '@/store/types/ICancelExport';
import closeheader from '@/components/closeheader.vue';

@Component({
  components: { closeheader },
})
export default class ExportProcessingPage extends Vue {
  exportCriteria: IExportCriteria | null = null;
  cancelExportButtonDisabled = false;

  // This this duplicated in DashboardExportsTabDesktopItem.vue
  async cancelExport() {
    this.cancelExportButtonDisabled = true;
    const processingUrl = window.location.pathname.replace(
      '/processing',
      '',
    );
    await ExportUiStateModule.cancelCurentExportJob({
      exportProcessingHistoryId: Number(
        this.$route.params.exporthistoryid,
      ),
      processingUrl: processingUrl,
    } as ICancelExport);
    await ExplorerDataModule.loadExplorerDataAndRecords();
  }

  get processingExport() {
    return ExportUiStateModule.processingExportById(
      Number(this.$route.params.exporthistoryid),
    );
  }
}
